import React, { Component } from 'react'
import styled from '@emotion/styled'

const Wrap = styled.div`
  position: relative;
  margin: 0 0 2rem 0;
  width: 100%;
`
const Input = styled.textarea`
  background: ${props => props.theme.lightGray};
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 3px;
  padding: 0.5rem 0 0 0.5rem;
  color: ${props => props.theme.black};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  min-width: 100%;
  max-width: 100%;
  min-height: 4rem;
  transition: all ease 300ms;
  &:focus {
    background: ${props => props.theme.white};
  }
  &.hide-label {
    & ~ label {
      display: none;
    }
  }
`
const Label = styled.label`
  color: ${props => props.theme.gray};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  min-height: 2rem;
  position: absolute;
  top: 0.5rem;
  left: 0;
  transform: translateX(0.5rem);
`
const hideLabel = e => {
  if (e.target.value !== '') {
    e.target.classList.add('hide-label')
  } else {
    console.log('show label')
    e.target.classList.remove('hide-label')
  }
}
export default class TextArea extends Component {
  render() {
    return (
      <Wrap>
        <Input
          id={this.props.id}
          name={this.props.name}
          required={this.props.required}
          rows={this.props.rows}
          cols={this.props.cols}
          onInput={hideLabel}
          onChange={this.props.onChange}
          data-input
        />
        <Label htmlFor={this.props.id}>{this.props.label}</Label>
      </Wrap>
    )
  }
}
