import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ButtonWrap = styled.button`
  background: ${props => props.theme.primary};
  padding: 14px 24px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 15px; /* identical to box height */
  text-align: justify;
  color: ${props => props.theme.black};
  border-radius: 3px;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.primary};
  transition: all ease 150ms;
  &:hover {
    background: ${props => props.theme.black};
    color: ${props => props.theme.white};
  }
`

export default class Button extends Component {
  render() {
    if (this.props.icon) {
      return (
        <ButtonWrap
          css={css`
            z-index: ${this.props.zIndex};
          `}
          onClick={this.props.onClick}
        >
          {this.props.children} <FontAwesomeIcon icon={this.props.icon} />
        </ButtonWrap>
      )
    }
    return (
      <ButtonWrap
        css={css`
          z-index: ${this.props.zIndex};
        `}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </ButtonWrap>
    )
  }
}
